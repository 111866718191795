import request from '@manage/utils/request'

export function handleCheckpublicmatchauth(id) { //审核发布权限(开启关闭)
  return request({
    url: 'admin/checkpublicmatchauth/check',
    method: 'post',
    params: {
      id: id
    }
  })
}


export function handlePageQueryMatchPublicAuth(data) { //分页查询比赛发布权限
  return request({
    url: 'admin/checkpublicmatchauth/pageQueryMatchPublicAuth',
    method: 'post',
    data
  })
}


export function handleDetails(id) { //发布赛事权限详情
  return request({
    url: 'admin/checkpublicmatchauth/details',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handleSave(data) { //发布赛事权限详情
  return request({
    url: 'admin/checkpublicmatchauth/save',
    method: 'post',
    data
  })
}
