import {
	Message
} from 'element-ui'
import {
	handleCheckpublicmatchauth,
	handlePageQueryMatchPublicAuth,
	handleDetails,
	handleSave
} from '@manage/api/bm/accoutauthmanage';
export default {
	name: 'accoutauthmanage',
	components: {},
	data() {
		return {
			modiyInfoDialogVisible: false,
			matchAuthForm: {},
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			matchAuthRules: {
				company: [{
					required: true,
					message: '请输入单位全称',
					trigger: 'blur'
				}],
				liablePerson: [{
					required: true,
					message: '请输入联系人',
					trigger: 'blur'
				}],
				concatPhone: [{
					required: true,
					message: '请输入联系方式',
					trigger: 'blur'
				}]
			},
		}
	},
	watch: {

	},
	created() {
		this.fastQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryMatchPublicAuth({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				company: this.queryForm.company,
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		check(id) { //开启、关闭发布比赛权限
			handleCheckpublicmatchauth(id).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		openModiyInfoDialog(id) { //打开修改对话框
			this.modiyInfoDialogVisible = true;
			handleDetails(id).then(res => {
				if (res.status == 200) {
					this.matchAuthForm = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		submitInfo() { //保存信息
			handleSave(this.matchAuthForm).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.modiyInfoDialogVisible = false;
					this.pageQuery();
					this.matchAuthForm = {};
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		resetForm() {
			this.queryForm = {};
			this.fastQuery();
		},
	}
}